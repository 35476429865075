import React, { useState } from "react";
import { navigate } from "gatsby";
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

const Scanner = () => {
    const [result, setResult] = useState("Scanning ...");

    const handleScan = data => {
        if (data) {
            /* This shows the url scanned from the qrcode just before navigation happens - remove the next line if you dont want it displayed */
            setResult(`result: ${data}`);
            /**
             * The strategy here is that the qrcodes are encoded like https://ffg-gsc-demo.netlify.app/map
             * So, we simply split the string into array based on backslashes '/'
             * A valid url will always give us the relative location path from the domain starting at array position 3
             * We can then just use Gatsby navigate to send the user to that local, relative URL and we are done
             * Anything that does not mach will automatically got to the Gatsby 404 page.
             * No url is ever followd that is not local and relative, so its completely secure.
             */
            const relativeURL = data
                .split("/")
                .slice(3)
                .join("/");
            navigate(`/${relativeURL}`);
        }
    };

    const handleError = err => {
        // We should look at errorBoundaries, see https://reactjs.org/docs/error-boundaries.html
        console.error(err);
    };
    /* Hack to let gatsby build without causing its compile time SSR to error
     * see this https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined
     */
    if (typeof window !== `undefined`) {
        const QrReader = require("react-qr-reader");
        return (
            <Layout>
                <Helmet>
                    <title>QR Code Scanner - GSC Augmented Reality</title>
                    <meta name="description" content="QR Code Scanner for Glasgow Science Centre - Augmented Reality experiences"/>
                    <link rel="canonical" href="/scanner" />
                </Helmet>
                <>
                    <div className="scanner-panel">
                        <QrReader
                            delay={300}
                            facingMode="environment"
                            showViewFinder="true"
                            resolution={600}
                            style={{
                                height: "400px",
                                width: "400px"
                            }}
                            onError={handleError}
                            onScan={handleScan}
                        />
                    </div>
                    <div className="results">
                        <p>{result}</p>
                    </div>
                </>
            </Layout>
        );
    }
    return (
        <Layout>
            <Helmet>
                <title>GSC AR - Scanner</title>
                <meta name="description" content="Scanner for Glasgow Science Centre - Augmented Reality experiences" />
                <link rel="canonical" href="/scanner" />
            </Helmet>
            <></>
        </Layout>
    );
};

export default Scanner;
